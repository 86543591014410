<template>
  <div>
    <div
      :class="from === 'training' ? 'has-background-white' : ''"
      class="pell-editor pell"
    ></div>
  </div>
</template>

<script>
import { init, exec } from 'pell'
import 'pell/src/pell.scss'

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    from: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      content: this.value || '',
      editor: null
    }
  },
  watch: {
    content(newVal) {
      this.$emit('input', this.sanitizeHTML(newVal))
    }
  },
  mounted() {
    this.editor = init({
      element: this.$el.querySelector('.pell-editor'),
      onChange: html => {
        this.content = html
      },
      defaultParagraphSeparator: 'p',
      styleWithCSS: true,
      actions: [
        'bold',
        'italic',
        'underline',
        'heading1',
        'heading2',
        'paragraph',
        'olist',
        'ulist',
        'line',
        {
          name: 'link',
          result: () => {
            const url = window.prompt('Enter the link URL')
            if (url) exec('createLink', this.ensureHTTP(url))
          }
        }
      ],
      classes: {
        content: 'pell-content-custom'
      }
    })
    this.editor.content.innerHTML = this.sanitizeHTML(this.value)
  },
  methods: {
    ensureHTTP(str) {
      return (/^https?:\/\//.test(str) && str) || `http://${str}`
    },
    sanitizeHTML(input) {
      const div = document.createElement('div')
      div.innerHTML = input
      const scripts = div.getElementsByTagName('script')
      while (scripts.length) {
        scripts[0].parentNode.removeChild(scripts[0])
      }
      const elementsWithEventHandlers = div.querySelectorAll(
        '[onclick], [onload], [onerror], [onmouseover], [onfocus]'
      )
      elementsWithEventHandlers.forEach(element => {
        element.removeAttribute('onclick')
        element.removeAttribute('onload')
        element.removeAttribute('onerror')
        element.removeAttribute('onmouseover')
        element.removeAttribute('onfocus')
      })
      const links = div.querySelectorAll('a')
      links.forEach(link => {
        const href = link.getAttribute('href')
        if (href && href.startsWith('javascript:')) {
          link.removeAttribute('href')
        }
      })
      return div.innerHTML
    }
  }
}
</script>
<style>
.pell-content-custom {
  height: 100%;
  padding: 35px;
  outline: 0;
}
</style>
