<template>
  <div class="has-background-white" :class="getClass()">
    <nav
      class="navbar mukta is-fixed-top sticky has-padding-5 has-padding-left-5-mobile has-padding-right-5-mobile nav-shadow"
      :class="{ showShadow: shadow }"
      role="navigation"
      aria-label="main navigation"
    >
      <div
        class="container has-padding-right-15-mobile has-padding-left-15-mobile has-padding-right-80 has-padding-left-80"
      >
        <div class="navbar-brand is-left-marginless">
          <div class="pointer" @click="logoRedirection()">
            <img
              src="/images/brand/logo.svg"
              width="140"
              alt="Rojgari Logo"
              class="mr-2"
            />
          </div>
          <div class="desktop-hide postjob">
            <a
              v-if="loggedIn && $auth.user.employer_profile"
              class="button is-employer navbar-item is-size-7"
              :class="{
                'is-active has-text-white':
                  $route.path === '/en/job/post' || $route.path === '/job/post'
              }"
              @click="gotoJobPost()"
              v-text="
                $auth.user.has_free_post_balance
                  ? $t('Post a Bonus Job')
                  : $t('Post Job')
              "
            ></a>
            <a class="navbar-item" @click="changeLanguage">
              <span class="pr-2">
                <vue-icon-base
                  height="16"
                  width="16"
                  :path="$options.icons[$kc('uni-globe')]"
                >
                </vue-icon-base>
              </span>
              <template v-if="$i18n.locale === 'ne'">
                <span class="is-size-7">EN</span>
              </template>
              <template v-if="$i18n.locale === 'en'">
                <span class="is-size-7">NP</span>
              </template>
            </a>
          </div>
          <a
            role="button"
            class="navbar-burger burger"
            style="margin-left: 0 !important"
            :class="{ 'is-active': showNavbarItems }"
            aria-label="menu"
            aria-expanded="false"
            @click="showNavbarItems = !showNavbarItems"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div
          class="navbar-menu"
          :class="{ 'is-block': windowScreen === 'mobile' && showNavbarItems }"
        >
          <div class="navbar-start text-14 ml-5">
            <a
              id="btn-homepage"
              class="navbar-item"
              @click="$router.push(localePath({ name: 'index' }))"
              v-text="$t('homePage')"
            ></a>
            <a
              v-if="(loggedIn && $auth.user.jobseeker_profile) || !loggedIn"
              id="btn-browse-job"
              class="navbar-item"
              @click="
                $router.push(
                  localePath({
                    name: 'job-search'
                  })
                )
              "
              v-text="$t('Browse Job')"
            ></a>
            <a
              id="btn-faq"
              class="navbar-item"
              @click="
                $router.push(
                  localePath({
                    name: 'faq'
                  })
                )
              "
              v-text="$t('FAQs')"
            ></a>
            <a
              id="btn-training"
              class="navbar-item relative pr-0 is-flex"
              @click="
                $router.push(
                  localePath({
                    name: 'training'
                  })
                )
              "
            >
              <div>{{ $t('Trainings') }}</div>
              <div
                class="relative is-flex align-center content-center pb-1 pr-1"
              >
                <vue-icon-base
                  class="pt-1"
                  height="14"
                  width="14"
                  :path="$options.icons[$kc('uni-circle-fill')]"
                  iconColor="#ef636c"
                >
                </vue-icon-base>
                <span class="ping"></span></div
            ></a>
          </div>
          <div v-if="false" class="navbar-start">
            <div class="navbar-item field has-addons">
              <div class="control">
                <input
                  v-model="searchQuery"
                  :placeholder="getColorName().name"
                  :class="getColorName().color"
                  class="input is-outlined"
                  @change="gotoSearchPage"
                />
              </div>
              <div class="control">
                <a
                  :class="getColorName().color"
                  class="button is-outlined"
                  @click="gotoSearchPage"
                  v-text="$t('search')"
                >
                </a>
              </div>
            </div>
          </div>
          <div class="navbar-end text-14 gap-05rem">
            <template v-if="loggedIn">
              <template v-if="$auth.user.employer_profile">
                <a
                  id="btn-training"
                  class="navbar-item"
                  @click="
                    $router.push(
                      localePath({
                        name: 'pricing'
                      })
                    )
                  "
                >
                  <div class="text-orange">{{ $t('Pricing') }}</div>
                </a>
              </template>
              <template v-if="$auth.user.employer_profile">
                <a
                  id="overview"
                  class="navbar-item"
                  :class="{
                    'text-orange':
                      $route.query.tab === 'active-jobs' ||
                      $route.query.tab === 'pending-jobs' ||
                      $route.query.tab === 'draft-jobs' ||
                      $route.query.tab === 'denied-jobs' ||
                      $route.query.tab === 'expired-jobs'
                  }"
                  @click="viewProfile"
                >
                  {{ $t('Overview') }}
                </a>
              </template>
              <template
                v-if="
                  $auth.user.jobseeker_profile &&
                  !$auth.user.referrer_profile &&
                  this.$auth.user.has_preferred_locations === true &&
                  this.$auth.user.has_preferred_jobs === true &&
                  this.$auth.user.has_filled_description_form === true &&
                  this.$auth.user.has_working_status === true
                "
              >
                <a
                  id="overview"
                  class="navbar-item"
                  :class="{
                    'text-orange':
                      $route.query.tab === 'applied-jobs' ||
                      $route.query.tab === 'profile-view' ||
                      $route.query.tab === 'liked-jobs'
                  }"
                  @click="viewProfile"
                >
                  {{ $t('Overview') }}
                </a>
              </template>
              <template
                v-if="
                  $auth.user.jobseeker_profile && $auth.user.referrer_profile
                "
              >
                <a
                  id="overview"
                  class="navbar-item"
                  :class="{
                    'text-orange': $route.query.tab === 'my-referees'
                  }"
                  @click="viewReferrerProfile"
                >
                  {{ $t('Overview') }}
                </a>
              </template>
              <template>
                <div class="navbar-item has-dropdown is-hoverable">
                  <div class="pointer navbar-link">
                    <figure class="image is-24x24">
                      <img
                        :class="{
                          'jobseeker-navbar-profile-image ': !$auth.user
                            .employer_profile
                        }"
                        class="is-full-height is-rounded"
                        :src="getProfileImage()"
                        alt="Logo"
                        height="50px"
                        width="50px"
                      />
                    </figure>
                  </div>
                  <div class="navbar-dropdown is-right pt-0">
                    <div
                      class="columns pointer ma-0 pl-2 py-2"
                      @click="
                        $auth.user.jobseeker_profile &&
                        $auth.user.referrer_profile
                          ? viewReferrerProfile()
                          : viewProfile()
                      "
                    >
                      <div class="column is-4 pt-0 pl-2 pb-2 pr-0">
                        <figure class="image pa-0">
                          <img
                            :src="getProfileImage()"
                            class="is-full-height is-rounded"
                            style="width: 39px"
                          />
                        </figure>
                      </div>
                      <div class="column is-8" style="min-width: 250px">
                        <div>
                          <div>
                            {{ getProfileDetails().name }}
                          </div>
                          <div>
                            {{ getProfileDetails().number }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr class="ma-0 pa-0" />
                    <a class="navbar-item" @click="gotoChangePasswordPage()">
                      {{ $t('changePassword') }}
                    </a>
                    <a
                      v-if="$auth.user.employer_profile"
                      id="btn-explore-jobseeker-insights"
                      class="navbar-item"
                      @click="$router.push(localePath('jobseeker-insights'))"
                      v-text="$t('Explore Jobseeker Insights')"
                    ></a>
                    <a
                      v-if="$auth.user.jobseeker_profile"
                      id="btn-browse-job"
                      class="navbar-item"
                      @click="
                        $router.push(
                          localePath({
                            name: 'job-search'
                          })
                        )
                      "
                      v-text="$t('Browse Job')"
                    ></a>
                    <a
                      v-if="$auth.user.jobseeker_profile"
                      id="btn-faq"
                      class="navbar-item"
                      @click="
                        $router.push(localePath({ name: 'faq' })),
                          (showNavbarItems = false)
                      "
                      v-text="$t('FAQs')"
                    ></a>
                    <a class="navbar-item" @click="logout">
                      {{ $t('Logout') }}
                    </a>
                  </div>
                </div>
              </template>
            </template>
            <template v-else>
              <a
                id="btn-download-app"
                class="button text-14 has-text-weight-semibold mr-3 rounded is-jobseeker"
                @click="showQRmodal = true"
                v-text="$t('Download App')"
              ></a>
              <a
                v-if="(loggedIn && $auth.user.employer_profile) || !loggedIn"
                id="employer-zone"
                class="navbar-item-employer has-text-centered has-text-weight-bold"
                :class="
                  screen === 'mobile' ? 'border-brown button mt-2 rounded' : ''
                "
                @click="
                  $router.push(
                    localePath({
                      name: 'employer-zone'
                    })
                  ),
                    (showNavbarItems = false)
                "
                v-text="$t('Employer Zone')"
              ></a>
              <a
                id="btn-homepage"
                class="button text-14 mt-2 is-hidden-tablet-only rounded"
                @click="logoRedirection()"
                v-text="$t('homePage')"
              ></a>
              <a
                v-if="(loggedIn && $auth.user.jobseeker_profile) || !loggedIn"
                id="btn-browse-job"
                class="button text-14 mt-2 is-hidden-tablet-only rounded"
                @click="
                  $router.push(
                    localePath({
                      name: 'job-search'
                    })
                  ),
                    (showNavbarItems = false)
                "
                v-text="$t('Browse Job')"
              ></a>
              <a
                id="btn-faq"
                class="button text-14 mt-2 is-hidden-tablet-only rounded"
                @click="
                  $router.push(localePath({ name: 'faq' })),
                    (showNavbarItems = false)
                "
                v-text="$t('FAQs')"
              ></a>
              <a
                id="btn-training"
                class="text-14 mt-2 is-hidden-desktop is-hidden-tablet-only rounded relative pr-0 is-flex border-1-light-grey color-light-black padding-halfem content-center"
                @click="
                  $router.push(
                    localePath({
                      name: 'training'
                    })
                  ),
                    (showNavbarItems = false)
                "
              >
                <div>
                  {{ $t('Trainings') }}
                </div>
                <div
                  class="relative is-flex align-center content-center pb-1 pr-1"
                >
                  <vue-icon-base
                    class="pt-1"
                    height="14"
                    width="14"
                    :path="$options.icons[$kc('uni-circle-fill')]"
                    iconColor="#ef636c"
                  >
                  </vue-icon-base>
                  <span class="ping"></span></div
              ></a>
            </template>
            <div
              class="mobile-hide mt-2"
              style="display: flex; place-items: center"
            >
              <button
                v-if="loggedIn && $auth.user.employer_profile"
                id="btn-post-a-job"
                :disabled="
                  this.$auth.user.has_filled_initial_form === false || disabled
                "
                class="button is-employer navbar-item is-size-7"
                @click="gotoJobPost()"
                v-text="
                  this.$store.state.auth.user.has_free_post_balance
                    ? $t('Post a Bonus Job')
                    : $t('Post Job')
                "
              ></button>
              <a
                class="navbar-item is-flex d-placement"
                @click="changeLanguage"
              >
                <span class="pr-2">
                  <vue-icon-base
                    height="16"
                    width="16"
                    :path="$options.icons[$kc('uni-globe')]"
                  >
                  </vue-icon-base>
                </span>
                <template v-if="$i18n.locale === 'ne'">
                  <span class="is-size-7">EN</span>
                </template>
                <template v-if="$i18n.locale === 'en'">
                  <span class="is-size-7">NP</span>
                </template>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="is-relative" @click="showNavbarItems = false">
      <nuxt></nuxt>
    </div>
    <footer class="base has-padding-top-25 has-padding-bottom-15">
      <div class="container">
        <div
          class="columns has-text-white has-padding-left-30-mobile has-padding-right-30-mobile is-mobile is-multiline footer_size mx-3 has-padding-right-40 has-padding-left-40"
        >
          <div class="column is-4-desktop is-12-mobile">
            <div
              class="has-text-weight-bold is-size-5 pointer"
              @click="$router.push(localePath({ name: '/' }))"
            >
              <img
                src="/images/brand/rojgari-white.svg"
                alt="Rojgari"
                width="180"
              />
            </div>
            <div class="is-size-7 has-text-grey-lighter has-margin-bottom-20">
              {{ $t('rojgariAbout') }}
              <span
                class="pointer is-size-7 has-text-link"
                @click="$router.push(localePath({ name: 'about' }))"
                v-text="$t('viewMore')"
              ></span>
            </div>
            <div class="columns is-mobile">
              <div
                v-for="(icon, index) in socialMedia"
                :key="index"
                class="column is-narrow is-right-paddingless pointer"
                @click="openLink(icon.link)"
              >
                <vue-hover>
                  <template v-slot="{ hover }">
                    <vue-icon-base
                      height="16"
                      width="16"
                      :icon-color="hover ? '#e38c1d' : '#ffffff'"
                      :path="$options.icons[$kc(icon.text)]"
                    >
                    </vue-icon-base>
                  </template>
                </vue-hover>
              </div>
            </div>
          </div>
          <div class="column has-margin-top-5 is-half-mobile">
            <div class="has-text-weight-bold is-size-6 has-margin-bottom-10">
              {{ $t('Most Popular FAQs') }}
            </div>
            <div>
              <a
                class="has-text-grey-light is-size-6"
                @click="registerRedirect()"
              >
                <small
                  class="is-hover-blue"
                  v-text="$t('registerHere')"
                ></small>
              </a>
            </div>
            <div class="is-size-6">
              <a
                class="has-text-grey-light"
                @click="$router.push(localePath({ name: 'about' }))"
              >
                <small class="is-hover-blue" v-text="$t('aboutus')"></small>
              </a>
            </div>
            <div class="is-size-6">
              <a class="has-text-grey-light" @click="gotoBlogList()">
                <small class="is-hover-blue" v-text="$t('Blog')"></small
              ></a>
            </div>
            <div class="is-size-6">
              <a
                class="has-text-grey-light"
                @click="$router.push(localePath('referral'))"
              >
                <small
                  class="is-hover-blue"
                  v-text="$t('ApplyForReferral')"
                ></small
              ></a>
            </div>
          </div>
          <div class="column has-margin-top-5 is-half-mobile">
            <div class="has-text-weight-bold is-size-6 has-margin-bottom-10">
              {{ $t('jobseeker') }}
            </div>
            <div>
              <a
                class="has-text-grey-light is-size-6"
                @click="signinRedirect()"
              >
                <small class="is-hover-blue" v-text="$t('Sign In')"></small>
              </a>
            </div>
            <!-- @click="$router.push(localePath('signup-jobseeker'))" -->
            <div>
              <a class="has-text-grey-light" @click="jsregisterRedirect()">
                <small
                  class="is-hover-blue"
                  v-text="$t('registerHere')"
                ></small>
              </a>
            </div>
            <div>
              <a
                class="has-text-grey-light is-size-6"
                @click="$router.push(localePath('job-search'))"
              >
                <small class="is-hover-blue" v-text="$t('searchjob')"></small>
              </a>
            </div>
          </div>
          <div class="column has-margin-top-5 is-half-mobile">
            <div class="has-text-weight-bold is-size-6 has-margin-bottom-10">
              {{ $t('employer') }}
            </div>
            <a
              class="has-text-grey-light"
              @click="$router.push(localePath({ name: 'employer-zone' }))"
            >
              <small class="is-hover-blue" v-text="$t('Employer Zone')"></small>
            </a>
            <div>
              <a class="has-text-grey-light is-size-6" @click="gotoJobPost">
                <small
                  class="is-hover-blue"
                  v-text="
                    this.$auth.state.user &&
                    this.$auth.state.user.has_free_post_balance
                      ? $t('Post a Bonus Job')
                      : $t('Post Job')
                  "
                ></small>
              </a>
            </div>
            <div>
              <a
                class="has-text-grey-light"
                @click="$router.push(localePath('contact'))"
              >
                <small class="is-hover-blue" v-text="$t('Contact')"></small>
              </a>
              <div class="is-size-6">
                <a class="has-text-grey-light" @click="gotoPricing()">
                  <small class="is-hover-blue" v-text="$t('Pricing')"></small
                ></a>
              </div>
            </div>
            <div class="has-text-grey-light is-size-6"></div>
          </div>
          <div class="column has-text-grey-light has-margin-top-5 is-size-7">
            <div
              class="has-text-weight-bold has-text-white is-size-6 has-margin-bottom-10"
            >
              {{ $t('Contact') }}
            </div>
            <div class="is-size-7">
              {{ $t('Phone') }}:
              <span class="has-text-white" v-text="$t('branchOnephone')"></span>
            </div>

            <div class="is-size-7">
              {{ $t('Email') }}:
              <span class="has-text-white">info@rojgari.com</span>
            </div>

            <div class="is-size-7">
              {{ $t('Head Office') }}:
              <span
                class="has-text-white"
                v-html="$t('branchOnesubtitle')"
              ></span>
            </div>
          </div>
        </div>
      </div>
      <hr class="has-background-grey-dark footer-divider" />
      <div class="container">
        <div
          class="columns is-vcentered has-padding-left-30-mobile has-padding-right-30-mobile is-mobile has-text-white is-size-7 footer_size is-flex is-content-space-between is-flex-wrap gap-1 pb-2 is-full-width has-padding-right-80 has-padding-left-80"
        >
          <div class="column is-flex">
            {{ $t('2024 - Rojgari') }}
            |
            <a
              class="has-text-white"
              @click="$router.push(localePath({ name: 'terms-condition' }))"
            >
              <small
                class="is-hover-blue"
                v-text="$t('termsandcondition')"
              ></small>
            </a>
          </div>
          <div class="is-flex gap-1 is-flex-wrap align-center pl-3">
            <span>Powered by</span>
            <img
              class="pointer object-fit-contain height-2rem"
              src="/images/MJ_logo.png"
              alt="merojob"
              @click="openLink('https://merojob.com/')"
            />
          </div>
        </div>
      </div>
    </footer>
    <vue-snackbar></vue-snackbar>
    <div :class="{ 'is-active': accountsDialog.display }" class="modal">
      <div class="modal-background"></div>
      <account-base
        v-if="accountsDialog.display"
        :type="accountsDialog.type"
        :extra-info="accountsDialog.extraInfo"
        @change="
          setDialog({
            display: true,
            type: $event.type,
            extraInfo: $event.extraInfo
          })
        "
        @close="setDialog({ display: false, type: '' })"
      ></account-base>
      <button
        v-if="!org"
        class="modal-close is-large"
        @click="setDialog({ display: false, type: '' })"
      ></button>
    </div>
    <job-post-form
      v-if="displayJobPostForm"
      :display-job-post-form="displayJobPostForm"
      @close="closeModal"
      @open="displayJobSpecificationForm = true"
    ></job-post-form>
    <job-spec-form
      v-if="displayJobSpecificationForm"
      :display-job-spec-form="displayJobSpecificationForm"
      @close="closeModal"
      @open="displayAdditionalInfo = true"
      @openbasicform="displayJobPostForm = true"
      @closeSpecForm="displayJobSpecificationForm = false"
    ></job-spec-form>
    <job-add-form
      v-if="displayAdditionalInfo"
      :display-job-spec-form="displayAdditionalInfo"
      @close="closeModal"
      @closeAddForm="displayAdditionalInfo = false"
      @open="displayJobSpecificationForm = true"
    ></job-add-form>
    <div :class="{ 'is-active': displaySignUpChoices }" class="modal">
      <div class="modal-background"></div>
      <signup-choices
        v-if="displaySignUpChoices"
        @close="displaySignUpChoices = false"
      ></signup-choices>
      <button
        class="modal-close is-large"
        @click="displaySignUpChoices = false"
      ></button>
    </div>
    <buttom-navbar
      v-if="
        $store.getters['shoppingCart/hasShortlistedEmployerJobseeker'] &&
        !isCartSummaryPage
      "
    />
    <div v-if="showQRmodal" class="modal is-active">
      <div class="modal-background"></div>
      <section class="section">
        <div class="container">
          <div class="pt-5 px-5 has-background-white rounded-10 has-text-black">
            <div
              class="is-flex-tablet is-flex-widescreen is-inline-flex-desktop"
            >
              <div class="has-text-left has-text-black">
                <div class="is-flex is-align-items-center">
                  <div class="image is-96x96">
                    <img src="/images/app logo.png" alt="App Logo" />
                  </div>
                  <div class="ml-2">
                    <h2
                      class="title is-size-2-desktop is-size-3-mobile mt-2 mb-2"
                    >
                      Rojgari App
                    </h2>
                    <div class="is-flex gap-1-desktop gap-1 is-gapless-mobile">
                      <span
                        class="icon is-size-3-desktop is-size-3-tablet is-size-4-mobile has-text-warning"
                        >★</span
                      >
                      <span
                        class="icon is-size-3-desktop is-size-3-tablet is-size-4-mobile has-text-warning"
                        >★</span
                      >
                      <span
                        class="icon is-size-3-desktop is-size-3-tablet is-size-4-mobile has-text-warning"
                        >★</span
                      >
                      <span
                        class="icon is-size-3-desktop is-size-3-tablet is-size-4-mobile has-text-warning"
                        >★</span
                      >
                      <span
                        class="icon is-size-3-desktop is-size-3-tablet is-size-4-mobile has-text-warning"
                        >★</span
                      >
                    </div>
                  </div>
                </div>
                <div class="has-margin-top-50-tablet has-margin-top-10-mobile">
                  <p
                    class="subtitle is-size-4-mobile is-size-4-tablet is-size-3-desktop"
                  >
                    Download App Now!
                  </p>
                </div>
                <div
                  class="is-flex has-margin-top-50-tablet has-margin-top-10-mobile"
                >
                  <div class="mr-2">
                    <span
                      class="is-white is-rounded has-cursor-pointer is-one-third-mobile is-three-fifths-tablet is-full-desktop is-full-widescreen is-full-fullhd"
                      @click="gotoPlayStore"
                    >
                      <img
                        src="/images/account/android_download.png"
                        alt="Android Download"
                        style="max-width: 90%"
                      />
                    </span>
                  </div>
                  <div>
                    <span
                      class="is-white is-rounded has-cursor-pointer is-one-third-mobile is-three-fifths-tablet is-full-desktop is-full-widescreen is-full-fullhd"
                      @click="gotoAppleStore"
                    >
                      <img
                        src="/images/account/apple_download.png"
                        alt="Apple Download"
                        style="max-width: 90%"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div class="is-flex is-justify-content-center">
                <figure class="image">
                  <img
                    src="/images/new-app-showcase.png"
                    alt="QR Code"
                    class="is-one-third-mobile is-three-fifths-tablet is-full-desktop is-full-widescreen is-full-fullhd"
                    style="object-fit: cover"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <button
        class="modal-close has-background-black is-large mr-4"
        aria-label="close"
        @click="showQRmodal = false"
        style="z-index: 1000"
      ></button>
    </div>
  </div>
</template>

<script>
import AccountBase from '@/components/Accounts/AccountBase'
import VueSnackbar from '@/components/Commons/VueSnackbar'
import VueHover from '@/components/Commons/VueHover'
import ButtomNavbar from '@/components/ShoppingCart/FixedButtomNavbarCartStatus'
import SignupChoices from '@/components/Accounts/SignupChoices'
import { storePrefix } from '@/helpers/constants/shoppingCart'
import VueIconBase from '@/components/Commons/VueIconBase'
import {
  uniFacebook,
  uniInstagram,
  uniYoutube,
  uniGlobe,
  uniLinkedin,
  uniTwitter,
  uniGooglePlay,
  uniApple,
  uniTikTok,
  uniCircleFill
} from '@/helpers/icons'
import { mapState, mapGetters } from 'vuex'
import JobPostForm from '@/components/Job/Post/Forms/InitialForm'
import JobSpecForm from '@/components/Job/Post/Forms/JobSpecificationModal'
import JobAddForm from '@/components/Job/Post/Forms/AdditionalInfoModal'
export default {
  icons: {
    uniFacebook,
    uniInstagram,
    uniLinkedin,
    uniTwitter,
    uniYoutube,
    uniGlobe,
    uniGooglePlay,
    uniApple,
    uniTikTok,
    uniCircleFill
  },
  components: {
    VueIconBase,
    VueHover,
    AccountBase,
    VueSnackbar,
    ButtomNavbar,
    SignupChoices,
    JobPostForm,
    JobSpecForm,
    JobAddForm
  },
  data() {
    return {
      shadow: false,
      showQRmodal: false,
      showNavbarItems: false,
      searchQuery: '',
      displayJobPostForm: false,
      displayJobSpecificationForm: false,
      displayAdditionalInfo: false,
      userData: {},
      displaySignUpChoices: false,
      disabled: false,
      socialMedia: [
        {
          text: 'uni-facebook',
          link: 'https://www.facebook.com/RojgariServices/'
        },
        {
          text: 'uni-instagram',
          link: 'https://www.instagram.com/rojgariservices/'
        },
        {
          text: 'uni-tikTok',
          link: 'https://www.tiktok.com/@rojgari.com'
        },
        {
          text: 'uni-youtube',
          link: 'https://www.youtube.com/channel/UC7-4shjuaw0QH_k_npz6TiQ'
        },
        {
          text: 'uni-linkedin',
          link: 'https://www.linkedin.com/company/rojgari-services-pvt-ltd'
        },
        {
          text: 'uni-twitter',
          link: 'https://twitter.com/rojgari'
        },
        {
          text: 'uni-google-play',
          link:
            'https://play.google.com/store/apps/details?id=com.rojgaripasal.rojgariapp'
        },
        {
          text: 'uni-apple',
          link:
            'https://apps.apple.com/us/app/rojgari/id1493964641?ign-mpt=uo%3D2i'
        }
      ],
      windowScreen: null
    }
  },
  computed: {
    ...mapState('auth', ['loggedIn']),
    ...mapGetters({
      screen: 'getScreen',
      accountsDialog: 'getLoginDialog',
      org: 'accounts/currentOrg'
    }),
    isCartSummaryPage() {
      const filteredPages = [
        'payment-cart-summary___ne',
        'payment-cart-summary___en'
      ]
      return filteredPages.includes(this.$route.name)
    }
  },
  created() {
    this.$nuxt.$on('openinitialform', () => {
      this.displayJobPostForm = true
    })
    this.$nuxt.$on('JobSpecificationform', () => {
      this.displayJobPostForm = true
    })
    this.$nuxt.$on('disableJobPost', () => {
      this.disabled = true
    })
    this.$nuxt.$on('enableJobPost', () => {
      this.disabled = false
    })
    this.$nuxt.$on('openDownloadQRPopUp', () => {
      this.showQRmodal = true
    })
  },
  mounted() {
    window.addEventListener('resize', e => {
      this.setScreenSize(e.target.outerWidth)
    })
    this.setScreenSize(window.outerWidth)
    // sync Shopping Data
    const storeData = JSON.parse(
      localStorage.getItem(`${storePrefix}-employerJobseekerShortlist`) || '[]'
    )
    this.$store.dispatch('shoppingCart/syncFromStore', storeData)
    // end sync Shopping Data
    // if (this.$auth.user && this.$auth.user.employer_profile) {
    //   this.getShortlistedUserData()
    // }
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      const scroll = window.pageYOffset || document.documentElement.scrollTop

      if (scroll > 50) {
        this.shadow = true
      } else {
        this.shadow = false
      }
    },
    gotoPlayStore() {
      window.open(
        'https://play.google.com/store/apps/details?id=com.rojgaripasal.rojgariapp',
        '_blank'
      )
    },
    gotoAppleStore() {
      window.open(
        'https://apps.apple.com/us/app/rojgari/id1493964641?ign-mpt=uo%3D2',
        '_blank'
      )
    },
    getShortlistedUserData() {
      const endpoint = '/api/v1/payment/hiring-package/jobseekers'
      this.$axios.get(endpoint).then(response => {
        const storeData = response.data.hiring_package
          ? response.data.hiring_package.map(x => x.jobseeker)
          : []
        this.$store.dispatch('shoppingCart/syncFromStore', storeData)
      })
    },
    changeLanguage() {
      const switchLanguage = { en: 'ne', ne: 'en' }
      const searchParams = new URLSearchParams(window.location.search)
      const queryParams = {}
      for (const [key, value] of searchParams.entries()) {
        queryParams[key] = value
      }
      this.$router.replace(
        this.localePath(
          {
            name: this.getRouteBaseName(this.$route),
            params: this.$route.params,
            query: queryParams
          },
          switchLanguage[this.$i18n.locale]
        )
      )
      this.showNavbarItems = false
    },
    getColorName() {
      if (
        ['/', '/en', '/job/search', '/en/job/search'].includes(this.$route.path)
      ) {
        return { color: 'is-jobseeker', name: this.$t('searchjob') }
      } else if (this.get(this.$auth, 'user.employer_profile')) {
        return { color: 'is-employer', name: this.$t('Job Seeker Search') }
      } else if (this.get(this.$auth, 'user.jobseeker_profile')) {
        return { color: 'is-jobseeker', name: this.$t('searchjob') }
      } else if (
        [
          '/employer/zone',
          '/en/employer/zone',
          '/jobseeker/search',
          '/en/jobseeker/search'
        ].includes(this.$route.path)
      ) {
        return { color: 'is-employer', name: this.$t('Job Seeker Search') }
      } else {
        return { color: 'is-jobseeker', name: this.$t('searchjob') }
      }
    },
    gotoSearchPage() {
      let routeName = ''
      if (
        ['/', '/en', '/job/search', '/en/job/search'].includes(this.$route.path)
      ) {
        routeName = 'job-search'
      } else if (this.get(this.$auth, 'user.employer_profile')) {
        routeName = 'jobseeker-search'
      } else if (
        [
          '/employer/zone',
          '/en/employer/zone',
          '/jobseeker/search',
          '/en/jobseeker/search'
        ].includes(this.$route.path)
      ) {
        routeName = 'jobseeker-search'
      } else if (this.get(this.$auth, 'user.jobseeker_profile')) {
        routeName = 'job-search'
      } else {
        routeName = 'job-search'
      }
      this.$router.push(
        this.localePath({
          name: routeName,
          query: {
            search: this.searchQuery
          }
        })
      )
      this.showNavbarItems = false
    },
    viewProfile() {
      if (
        // this.$auth.user.has_filled_initial_form ||
        this.$auth.user.related_organizations.length
      ) {
        this.gotoProfile()
      } else if (
        this.$auth.user.jobseeker_profile &&
        !this.$auth.user.referrer_profile
      ) {
        this.getUserData()
      } else {
        this.setDialog({ display: true, type: 'details' })
      }
      this.showNavbarItems = false
    },
    viewReferrerProfile() {
      this.$router.push(
        this.localePath({
          name: `referrer-profile-id`,
          params: { id: 'me' },
          query: { tab: 'my-referees' }
        })
      )
    },
    getUserData() {
      this.$axios.get(`/api/v1/users/me/`).then(response => {
        this.userData = response.data
        if (this.userData.has_preferred_locations === false) {
          this.$router.push(
            this.localePath({
              name: 'signup-prefered-location'
            })
          )
        } else if (this.userData.has_preferred_jobs === false) {
          this.$router.push(
            this.localePath({
              name: 'signup-prefered-job-category'
            })
          )
        } else if (this.userData.has_filled_description_form === false) {
          this.$router.push(
            this.localePath({
              name: 'signup-basic-information'
            })
          )
        } else if (this.userData.has_working_status === false) {
          this.$router.push(
            this.localePath({
              name: 'signup-working-status'
            })
          )
        } else {
          this.gotoProfile()
        }
      })
    },
    gotoProfile() {
      const routePrefix = this.$auth.user.employer_profile
        ? 'employer'
        : 'jobseeker'
      const tab = this.$auth.user.employer_profile
        ? 'active-jobs'
        : 'applied-jobs'
      this.$router.push(
        this.localePath({
          name: `${routePrefix}-profile-id`,
          params: { id: 'me' },
          query: { tab: `${tab}` }
        })
      )
    },
    getProfileImage() {
      if (this.$auth.user.employer_profile) {
        if (
          this.$auth.user.related_organizations.length &&
          this.$auth.user.related_organizations[0].logo
        ) {
          return this.$auth.user.related_organizations[0].logo
        } else {
          return this.$auth.user.profile_picture
        }
      } else {
        return this.$auth.user.profile_picture
      }
    },
    getProfileDetails() {
      let data = {}
      if (this.$auth.user.employer_profile) {
        data = {
          name:
            (this.$auth.user.related_organizations &&
              this.$auth.user.related_organizations[0] &&
              this.$auth.user.related_organizations[0].name) ||
            'N/A',
          number: this.$auth.user.phone_number || 'N/A'
        }
      } else {
        data = {
          name: this.$auth.user.full_name || 'N/A',
          number: this.$auth.user.phone_number || 'N/A'
        }
      }
      return data
    },
    setScreenSize(width) {
      let screen = this.windowScreen
      if (process.env.mobile[0] <= width && width <= process.env.mobile[1]) {
        screen = 'mobile'
      } else if (
        process.env.tablet[0] <= width &&
        width <= process.env.tablet[1]
      ) {
        screen = 'tablet'
      } else if (
        process.env.desktop[0] <= width &&
        width <= process.env.desktop[1]
      ) {
        screen = 'desktop'
      } else if (
        process.env.widescreen[0] <= width &&
        width <= process.env.widescreen[1]
      ) {
        screen = 'widescreen'
      } else if (
        process.env.fullhd[0] <= width &&
        width <= process.env.fullhd[1]
      ) {
        screen = 'fullhd'
      }
      this.$store.commit('setScreen', screen)
      this.windowScreen = screen
    },
    openLink(url) {
      window.open(url, '_blank')
    },
    gotoJobseekerRegistrationOverview() {
      this.$router.push(
        this.localePath({
          name: 'jobseeker-stats'
        })
      )
    },
    gotoChangePasswordPage() {
      this.$router.push(
        this.localePath({
          name: 'settings-change-password'
        })
      )
    },
    openEmployerPage(page) {
      this.$router.push(
        this.localePath({
          name: page
        })
      )
    },
    gotoBlogList() {
      this.$router.push(
        this.localePath({
          name: 'blog'
        })
      )
    },
    gotoPricing() {
      this.$router.push(
        this.localePath({
          name: 'pricing'
        })
      )
    },
    gotoSurvey() {
      this.$router.push(
        this.localePath({
          name: 'jobseeker-registration'
        })
      )
    },
    gotoJobPost() {
      if (this.$auth.loggedIn && this.$auth.user.employer_profile) {
        if (this.$auth.user.has_filled_initial_form) {
          if (
            this.$auth.user.related_organizations.length &&
            (this.$auth.user.is_purchase_available ||
              this.$auth.user.has_free_post_balance)
          ) {
            this.displayJobPostForm = true
          } else if (
            !this.$auth.user.is_purchase_available &&
            !this.$auth.user.has_free_post_balance
          ) {
            this.$router.push(
              this.localePath({
                name: 'pricing'
              })
            )
          } else {
            this.setDialog({ display: true, type: 'details' })
            this.notifyError(this.$t('Initial profile form not filled'))
          }
        } else {
          this.setDialog({ display: true, type: 'details' })
          this.notifyError(this.$t('Initial profile form not filled'))
        }
      } else {
        this.setDialog({
          display: true,
          type: 'employer-sign-in',
          extraInfo: 'employer-sign-in'
        })
        this.notifyError(
          this.$t('You need to login as an employer to post a job.')
        )
      }
    },
    logoRedirection() {
      this.$router.push(this.localePath('index'))
      this.showNavbarItems = false
    },
    getClass() {
      if (
        this.displayJobPostForm === true ||
        this.displayJobSpecificationForm === true ||
        this.displayAdditionalInfo === true
      ) {
        const over = 'bodyoverflow'
        return `${over}`
      }
    },
    closeModal() {
      this.displayJobPostForm = false
      this.displayJobSpecificationForm = false
      this.displayAdditionalInfo = false
      history.pushState({}, '', document.URL.split('job')[0])
    },
    goToSignup() {
      this.showNavbarItems = false
      this.$router.push(
        this.localePath({
          name: 'signup'
        })
      )
    },
    checkUser() {
      this.$auth.user.jobseeker_profile
        ? this.$router.push(
            this.localePath({
              name: `jobseeker-profile-id`,
              params: { id: 'me' },
              query: { tab: 'overview' }
            })
          )
        : this.$router.push(
            this.localePath({
              name: `employer-profile-id`,
              params: { id: 'me' },
              query: { tab: 'overview' }
            })
          )
    },
    signinRedirect() {
      if (this.$auth.loggedIn === true) {
        this.checkUser()
      } else {
        this.setDialog({ display: true, type: 'sign-in' })
      }
    },
    jsregisterRedirect() {
      if (this.$auth.loggedIn === true) {
        this.checkUser()
      } else {
        this.$router.push(this.localePath('signup-jobseeker'))
      }
    },
    registerRedirect() {
      if (this.$auth.loggedIn === true) {
        this.checkUser()
      } else {
        this.$router.push(this.localePath('signup'))
      }
    }
  }
}
</script>
<style lang="css" scoped>
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap');

.mukta {
  font-family: 'Mukta', sans-serif;
  padding-left: 120px;
  padding-right: 120px;
  padding-top: 80px;
  padding-bottom: 80px;
}
.jobseeker-navbar-profile-image {
  border-radius: 100px;
}
.footer-divider {
  height: 1px !important;
}
.is-hover-blue:hover {
  color: hsl(217, 71%, 53%);
}
a.navbar-item:hover {
  color: #e49228;
}
a.navbar-item-employer {
  color: #4a4a4a;
}
a.navbar-item-employer:hover {
  color: #723116;
}
.bodyoverflow {
  overflow: hidden;
  height: 100vh;
}
.nav-shadow.showShadow {
  box-shadow: rgb(220, 220, 220) 0px 2px 10px;
}
@keyframes ping {
  0% {
    transform: scale(0.1);
    opacity: 0.7;
  }
  80%,
  100% {
    transform: scale(2.4);
    opacity: 0;
  }
}
.ping::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ef636c;
  animation: ping 1.5s cubic-bezier(0, 0, 0.2, 1) infinite;
  z-index: -1;
}
</style>
